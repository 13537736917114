import { useAuth0 } from "@auth0/auth0-react";
import * as R from "ramda";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ConnectorTableResponse,
  GetAppsResponse,
  createConnectCard,
  createConversation,
  deletePullConnector as deletePullConnectorApi,
  deleteTable,
  getApps,
  getConnectorTables,
  getConnectorTypes,
  getConnectors,
  getNotebook,
  getRoles,
  getTable,
  getTableSpec,
  replyConversation as replyConversationApi,
} from "./api";
import { useSelectMainNote } from "./hook/note";
import { useFetchPushConnectors } from "./hook/pushConnector";
import { useHandleGetRolesResponse } from "./hook/role";
import { useSubscriptionStatus } from "./hook/settings";
import { getTableIds } from "./notebook";
import {
  ActionType,
  AddTablePageConfig,
  App,
  AppDispatch,
  ConnectCard,
  Connector,
  ConnectorType,
  Message,
  MessageRole,
  Note,
  PageContentType,
  RootState,
  SelectedPage,
} from "./reducer";
import { Table, TableMap, TableSpec, TableSpecMap } from "./types/table";

export const useNotebook: () => [any, (notebook: any) => void] = () => {
  const notebook = useSelector((state: RootState) => state.main.notebook);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedNote, setSelectedNote] = useSelectedNote();
  const addTables = useAddTables();
  const setTableSpecs = useSetTableSpecs();
  const [appId, setAppId] = useAppId();
  // const deleteNoteTable = useDeleteNoteTable();

  const fetchTables: (config: {
    tableIds: string[];
    appId: string;
  }) => Promise<Table[]> = ({ appId, tableIds }) => {
    return Promise.all(
      R.map((tableId: string) => getTable({ appId, tableId }), tableIds)
    );
  };

  const fetchTableSpecs: (config: {
    tableIds: string[];
    appId: string;
  }) => Promise<TableSpec[]> = ({ appId, tableIds }) => {
    return Promise.all(
      R.map((tableId: string) => getTableSpec({ appId, tableId }), tableIds)
    );
  };

  const setNotebook = (notebook: any) => {
    dispatch({ type: ActionType.SET_NOTEBOOK, notebook });
    const tableIds = getTableIds(notebook);
    fetchTableSpecs({ tableIds, appId: appId! }).then((tableSpecs) => {
      const nonErrorTableIds = tableSpecs
        .filter((tableSpec) => !tableSpec["execute-error"])
        .map((tableSpec) => tableSpec["table-id"]);
      fetchTables({ tableIds: nonErrorTableIds, appId: appId! }).then(
        (tables) => {
          addTables(tables);
        }
      );
      const tableSpecMap: TableSpecMap = Object.fromEntries(
        R.map((tableSpec) => [tableSpec["table-id"], tableSpec], tableSpecs)
      );
      setTableSpecs(tableSpecMap);
    });
  };

  return [notebook, setNotebook];
};

export const useTableSpec: (tableId?: string) => TableSpec | undefined = (
  tableId
) => {
  const tableSpec = useSelector((state: RootState) =>
    tableId ? state.main.tableSpecs[tableId] : undefined
  );
  return tableSpec;
};

export const useAppId: () => [
  string | undefined,
  (notebook: string) => void
] = () => {
  const appId = useSelector((state: RootState) => state.main.appId);
  const dispatch = useDispatch<AppDispatch>();
  const setNotebook = (appId: string) =>
    dispatch({ type: ActionType.SET_APP_ID, appId });
  return [appId, setNotebook];
};

export const useApps: () => [App[], (apps: App[]) => void] = () => {
  const appId = useSelector((state: RootState) => state.main.apps);
  const dispatch = useDispatch<AppDispatch>();
  const setNotebook = (apps: App[]) =>
    dispatch({ type: ActionType.SET_APPS, apps });
  return [appId, setNotebook];
};

export const useSelectedPage: () => [
  SelectedPage,
  (selectedPage: SelectedPage) => void
] = () => {
  const selectedPage = useSelector(
    (state: RootState) => state.main.selectedPage
  );
  const dispatch = useDispatch<AppDispatch>();
  const setSelectedPage = (selectedPage: SelectedPage) =>
    dispatch({ type: ActionType.SET_SELECTED_PAGE, selectedPage });
  return [selectedPage, setSelectedPage];
};

export const useChatId: () => [
  string | undefined,
  (chatId: string) => void
] = () => {
  const chatId = useSelector((state: RootState) => state.main.chatId);
  const dispatch = useDispatch<AppDispatch>();
  const setChatId = (chatId: string) =>
    dispatch({ type: ActionType.SET_CHAT_ID, chatId });
  return [chatId, setChatId];
};

export const useChatDrawerOpen: () => [
  boolean,
  (chatDrawerOpen: boolean) => void
] = () => {
  const chatDrawerOpen = useSelector(
    (state: RootState) => state.main.chatDrawerOpen
  );
  const dispatch = useDispatch<AppDispatch>();
  const setChatDrawerOpen = (chatDrawerOpen: boolean) =>
    dispatch({ type: ActionType.SET_CHAT_DRAWER_OPEN, chatDrawerOpen });
  return [chatDrawerOpen, setChatDrawerOpen];
};

export const useToggleChatDrawerOpen: () => () => void = () => {
  const chatDrawerOpen = useSelector(
    (state: RootState) => state.main.chatDrawerOpen
  );
  const dispatch = useDispatch<AppDispatch>();
  const toggleChatDrawerOpen = () =>
    dispatch({
      type: ActionType.SET_CHAT_DRAWER_OPEN,
      chatDrawerOpen: !chatDrawerOpen,
    });
  return toggleChatDrawerOpen;
};

export const useSideNavOpen: () => [
  boolean,
  (sideNavOpen: boolean) => void
] = () => {
  const sideNavOpen = useSelector((state: RootState) => state.main.sideNavOpen);
  const dispatch = useDispatch<AppDispatch>();
  const setSideNavOpen = (sideNavOpen: boolean) =>
    dispatch({ type: ActionType.SET_SIDE_NAV_OPEN, sideNavOpen });
  return [sideNavOpen, setSideNavOpen];
};

export const useToggleSideNavOpen: () => () => void = () => {
  const sideNavOpen = useSelector((state: RootState) => state.main.sideNavOpen);
  const dispatch = useDispatch<AppDispatch>();
  const toggleSideNavOpen = () =>
    dispatch({
      type: ActionType.SET_SIDE_NAV_OPEN,
      sideNavOpen: !sideNavOpen,
    });
  return toggleSideNavOpen;
};

export const useMessages: () => [
  Message[],
  (messages: Message[]) => void
] = () => {
  const messages = useSelector((state: RootState) => state.main.messages);
  const [appId, setAppId] = useAppId();
  const dispatch = useDispatch<AppDispatch>();
  const setMessages = (messages: Message[]) =>
    dispatch({ type: ActionType.SET_MESSAGES, messages });

  useEffect(() => {
    setMessages([]);
  }, [appId]);

  return [messages, setMessages];
};

export const useAddMessage: () => (message: Message) => void = () => {
  const dispatch = useDispatch<AppDispatch>();
  const addMessage = (message: Message) =>
    dispatch({ type: ActionType.ADD_MESSAGE, message });
  return addMessage;
};

export const useConnectors: () => [
  Connector[],
  (connectors: Connector[]) => void
] = () => {
  const connectors = useSelector((state: RootState) => state.main.connectors);
  const dispatch = useDispatch<AppDispatch>();
  const setConnectors = (connectors: Connector[]) =>
    dispatch({ type: ActionType.SET_CONNECTORS, connectors });
  return [connectors, setConnectors];
};

export const useConnectorTypes: () => [
  ConnectorType[],
  (connectorTypes: ConnectorType[]) => void
] = () => {
  const connectorTypes = useSelector(
    (state: RootState) => state.main.connectorTypes
  );
  const dispatch = useDispatch<AppDispatch>();
  const setConnectorTypes = (connectorTypes: ConnectorType[]) =>
    dispatch({ type: ActionType.SET_CONNECTOR_TYPES, connectorTypes });
  return [connectorTypes, setConnectorTypes];
};

export const useSelectedConnectorType: () => [
  any,
  (connectorTypes: any) => void
] = () => {
  const selectedConnectorType = useSelector(
    (state: RootState) => state.main.selectedConnectorType
  );
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const dispatch = useDispatch<AppDispatch>();
  const setSelectedConnectorType = (selectedConnectorType: any) => {
    dispatch({
      type: ActionType.SET_SELECTED_CONNECTOR_TYPE,
      selectedConnectorType,
    });
    setSelectedPage({ contentType: PageContentType.ADD_CONNECT_CARD });
  };
  return [selectedConnectorType, setSelectedConnectorType];
};

export const useSelectedConnectCard: () => [
  ConnectCard | undefined,
  (selectedConnectCard: ConnectCard) => void
] = () => {
  const selectedConnectCard = useSelector(
    (state: RootState) => state.main.selectedConnectCard
  );
  const dispatch = useDispatch();
  const setSelectedConnectCard = (selectedConnectCard: ConnectCard) => {
    dispatch({
      type: ActionType.SET_SELECTED_CONNECT_CARD,
      selectedConnectCard,
    });
  };
  return [selectedConnectCard, setSelectedConnectCard];
};

export const useFetchSelectedConnectCard: () => any = () => {
  const [selectedConnectorType, setSelectedConnectorType] =
    useSelectedConnectorType();
  const [selectedConnectCard, setSelectedConnectCard] =
    useSelectedConnectCard();
  const selectedConnectorTypeId = selectedConnectorType?.id;
  const selectedConnectCardId = selectedConnectCard?.id;
  const [appId, setAppId] = useAppId();

  useEffect(() => {
    if (!selectedConnectCardId && selectedConnectorTypeId && appId) {
      createConnectCard({
        appId: appId!,
        schema: selectedConnectorTypeId,
      }).then((response) => {
        const card = response["connect-card"];
        setSelectedConnectCard(card);
      });
    }
  }, [selectedConnectCardId, selectedConnectorTypeId, appId]);
};

export const useSelectedNote: () => [
  Note | undefined,
  (note: Note) => void
] = () => {
  const selectedNote = useSelector(
    (state: RootState) => state.main.selectedNote
  );
  const dispatch = useDispatch<AppDispatch>();
  const setSelectedNote = (selectedNote: Note) =>
    dispatch({ type: ActionType.SET_SELECTED_NOTE, selectedNote });
  return [selectedNote, setSelectedNote];
};

export const useNoWorkspaces: () => [
  boolean,
  (noWorkspaces: boolean) => void
] = () => {
  const noWorkspaces = useSelector(
    (state: RootState) => state.main.noWorkspaces ?? false
  );
  const dispatch = useDispatch();
  const setNoWorkspaces = (noWorkspaces: boolean) => {
    dispatch({
      type: ActionType.SET_NO_WORKSPACES,
      noWorkspaces,
    });
  };
  return [noWorkspaces, setNoWorkspaces];
};

export const useAppOwner: () => [
  string | undefined,
  (appOwner: string | undefined) => void
] = () => {
  const appOwner = useSelector((state: RootState) => state.main.appOwner);
  const dispatch = useDispatch<AppDispatch>();
  const setAppOwner = (appOwner: string | undefined) =>
    dispatch({ type: ActionType.SET_APP_OWNER, appOwner });
  return [appOwner, setAppOwner];
};

export const useFetchAppId: () => () => Promise<void> = () => {
  const [appId, setAppId] = useAppId();
  const [authTokenMaybe, setAuthToken] = useAuthToken();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [noWorkspaces, setNoWorkspaces] = useNoWorkspaces();
  const [appOwner, setAppOwner] = useAppOwner();
  const [apps, setApps] = useApps();
  const authToken = authTokenMaybe!;

  const setAppFromResponse = (response: GetAppsResponse) => {
    const selectedApp = response["selected-app"]
      ? response.apps?.[0]
      : undefined;
    const selectedAppId = selectedApp?.id;
    const appOwner = selectedApp?.["owner-email"];
    setAppOwner(appOwner);
    const apps = response.apps;
    setApps(apps);
    selectedAppId ? setAppId(selectedAppId) : setNoWorkspaces(true);
  };

  return async () => {
    setSelectedPage({ contentType: PageContentType.LOADING_ACCOUNT });
    getApps({ authToken }).then(setAppFromResponse);
  };
};

export const useAddTablePages: () => (
  config: AddTablePageConfig
) => void = () => {
  const dispatch = useDispatch();
  const addTablePages = (config: AddTablePageConfig) => {
    dispatch({ type: ActionType.ADD_TABLE_PAGE, ...config });
  };
  return addTablePages;
};

export const useTablePage: (config: {
  tableId?: string;
  index: number;
}) => string[][] | undefined = ({ tableId, index }) => {
  const tablePages = useSelector((state: RootState) =>
    tableId ? state.main.tablePages[tableId]?.[index] : undefined
  );
  return tablePages;
};

export const useAddTables: () => (tables: Table[]) => void = () => {
  const dispatch = useDispatch();
  const addTablePages = useAddTablePages();
  const addTables = (tables: Table[]) => {
    dispatch({ type: ActionType.ADD_TABLES, tables });
    tables.forEach((table) => {
      if (table.rows) {
        addTablePages({
          tableId: table["table-id"],
          index: 0,
          rows: table.rows,
        });
      }
    });
  };
  return addTables;
};

export const useSetTableSpecs: () => (
  tableSpecs: TableSpecMap
) => void = () => {
  const dispatch = useDispatch();
  const setTableSpecs = (tableSpecs: TableSpecMap) => {
    dispatch({ type: ActionType.SET_TABLE_SPECS, tableSpecs });
  };
  return setTableSpecs;
};

export const useAssociateConnectorTables: () => (
  connectorTables: any
) => void = () => {
  const setSelectedPullConnector = useSetSelectedPullConnector();
  const dispatch = useDispatch();
  const associateConnectorTables = (connectorTables: any) => {
    dispatch({ type: ActionType.ASSOCIATE_CONNECTOR_TABLES, connectorTables });
    // const connectorId = Object.keys(connectorTables)[0];
    // const tableId = connectorTables[connectorId][0]["file-id"];
    // setSelectedPullConnector({ connectorId, tableId });
  };
  return associateConnectorTables;
};

export const useHandleConnectorsResponse: () => (
  connectorsResponse: any
) => void = () => {
  const [appId, setAppId] = useAppId();
  const associateConnectorTables = useAssociateConnectorTables();
  const addTables = useAddTables();
  const [connectors, setConnectors] = useConnectors();
  const setSelectedPullConnector = useSetSelectedPullConnector();

  const fetchConnectorTables: (config: {
    connectorIds: string[];
    appId: string;
  }) => Promise<ConnectorTableResponse[]> = ({ appId, connectorIds }) => {
    return Promise.all(
      R.map(
        (connectorId: string) => getConnectorTables({ appId, connectorId }),
        connectorIds
      )
    );
  };

  const handleConnectorsResponse = (connectorsResponse: any) => {
    const cards = R.sortBy(
      R.path(["card", "schema"]) as any,
      R.prop("cards", connectorsResponse)
    ) as any[];
    const connectorIds = R.map(R.prop("connection-id"), cards);
    fetchConnectorTables({ appId: appId!, connectorIds }).then((responses) => {
      const connectorEntries = R.map((response) => {
        const conId = response["connector-id"];
        const tables = response.tables;
        return [conId, tables];
      }, responses);
      const connectorData = Object.fromEntries(connectorEntries);
      associateConnectorTables(connectorData);
      const tables = R.chain(R.prop("tables"), responses);
      addTables(tables);
      const tablesMap = Object.fromEntries(
        tables.map((table) => [table["table-id"], table])
      );
      // setSelectedPullConnector({
      //   connectorId: "breeder_officiating",
      //   tableId: "6c27f5e3-e747-40c8-bc18-7a29b4ecd805",
      //   tables: tablesMap,
      // });
    });
    setConnectors(cards);
  };
  return handleConnectorsResponse;
};

export const useHandleCreateConversation: () => (
  createConversationResponse: any
) => void = () => {
  const [chatId, setChatId] = useChatId();
  const replyConversation = useReplyConversation();

  const handleCreateConversation = (createConversationResponse: any) => {
    const chatId = createConversationResponse["chat-id"];
    setChatId(chatId);
    // replyConversation({
    //   content:
    //     "Give me the full names from the transformed go high level contacts table.",
    //   chatId,
    // });
  };
  return handleCreateConversation;
};

export const useFetchAppData: () => void = () => {
  const [notebook, setNotebook] = useNotebook();
  const [appId, setAppId] = useAppId();
  const [chatId, setChatId] = useChatId();
  const [connectors, setConnectors] = useConnectors();
  const [connectorTypes, setConnectorTypes] = useConnectorTypes();
  const handleConnectorsResponse = useHandleConnectorsResponse();
  const handleCreateConversation = useHandleCreateConversation();
  const fetchPushConnectors = useFetchPushConnectors();
  const selectMainNote = useSelectMainNote();
  const [sideNav, setSideNavOpen] = useSideNavOpen();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const handleGetRolesResponse = useHandleGetRolesResponse();
  const { user } = useAuth0();
  const { hasAccess, loading } = useSubscriptionStatus();

  useEffect(() => {
    if (loading) return;
    if (!notebook) return;
    if (hasAccess) {
      // setSelectedPage({
      //   contentType: PageContentType.SETTINGS,
      // });
      selectMainNote();
    } else {
      setSelectedPage({
        contentType: PageContentType.SETTINGS,
      });
    }
  }, [loading, notebook]);

  useEffect(() => {
    if (!appId || !hasAccess) return;

    getRoles({ email: user?.email! }).then(handleGetRolesResponse);
    getNotebook({ appId }).then((notebook) => {
      setNotebook(notebook);
    });
    getConnectors({ appId }).then((connectorsResponse) => {
      handleConnectorsResponse(connectorsResponse);
      setSideNavOpen(true);
    });
    createConversation({ appId }).then(handleCreateConversation);
    getConnectorTypes().then((x) => {
      setConnectorTypes(x);
      // setSelectedPage({
      //   contentType: PageContentType.SELECT_CONNECT_CARD_TYPE,
      // });
    });
    fetchPushConnectors();
  }, [appId, hasAccess]);
};

export const useNotebookTableIds: () => string[] = () => {
  const [notebook, setNotebook] = useNotebook();
  const tableIds = R.chain(
    R.compose(
      R.map(R.prop("table-id") as any),
      R.path(["page-data", "blocks"]) as any
    ),
    notebook?.pages ?? []
  );
  return tableIds as string[];
};

export const useFetchApp: () => void = () => {
  const [authToken] = useAuthToken();
  const [appId, setAppId] = useAppId();
  const fetchAppId = useFetchAppId();

  useEffect(() => {
    if (!authToken) return;
    fetchAppId();
  }, [authToken]);

  useFetchAppData();
};

export const usePageIds: () => any = () => {
  const [notebook, setNotebook] = useNotebook();
  const pages = R.sortBy(R.prop("page-id") as any, notebook?.pages ?? []);
  return pages;
};

export const useTables = () => {
  const tables = useSelector((state: RootState) => state.main.tables);
  return tables;
};

export const useTable: (tableId?: string) => Table | undefined = (tableId) => {
  const table = useSelector((state: RootState) =>
    tableId ? state.main.tables?.[tableId] : undefined
  );
  return table;
};

export const useTableInputs: (tableId?: string) => Table[] = (tableId) => {
  const tableSpec = useTableSpec(tableId);
  const inputFileIds = tableSpec?.inputs ?? [];
  const tables = useSelector((state: RootState) => state.main.tables);
  const fileIdToTable = Object.fromEntries(
    Object.values(tables).map((table) => [table["file-id"], table])
  );
  const inputTables = inputFileIds
    .map((fileId) => fileIdToTable?.[fileId])
    .filter((x) => x);
  return inputTables;
};

export const useConnectorTables: (connectorId: string) => any[] = (
  connectorId
) => {
  const tables = useSelector(
    (state: RootState) => state.main.connectorTables?.[connectorId] ?? []
  );
  return tables;
};

export const useSetSelectedPullConnector: () => (config: {
  connectorId: string;
  tableId: string;
  tables?: TableMap;
}) => void = () => {
  const tablesFromHook = useTables();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [appId, setAppId] = useAppId();
  const addTables = useAddTables();
  const setSelectedPullConnector: (config: {
    connectorId: string;
    tableId: string;
    tables?: TableMap;
  }) => void = ({ connectorId, tableId, tables: tablesFromConfig }) => {
    const tables = tablesFromConfig ?? tablesFromHook;
    const table = tables[tableId];
    if (!table.rows) {
      getTable({ appId: appId!, tableId: table["table-id"] }).then((table) => {
        addTables([table]);
      });
    }
    setSelectedPage({
      contentType: PageContentType.PULL_CONNECTOR_TABLE,
      page: { connectorId, tableId },
    });
  };
  return setSelectedPullConnector;
};

export const useReplyConversation: () => (config: {
  content: string;
  chatId?: string;
}) => void = () => {
  const [chatIdFromState, setChatId] = useChatId();
  const [appId, setAppId] = useAppId();
  const addMessage = useAddMessage();
  const [notebook, setNotebook] = useNotebook();
  const [chatLoading, setChatLoading] = useChatLoading();
  const selectMainNote = useSelectMainNote();

  const replyConversation: (config: {
    content: string;
    chatId?: string;
  }) => void = ({ content, chatId: chatIdFromConfig }) => {
    const chatId = chatIdFromConfig ?? chatIdFromState;
    addMessage({ content, role: MessageRole.USER });
    setChatLoading(true);
    replyConversationApi({ content, chatId: chatId! })
      .then((replyResponse) => {
        const reply = replyResponse.reply;
        addMessage({ content: reply, role: MessageRole.ASSISTANT });
        setChatLoading(false);
      })
      .then((response) => {
        getNotebook({ appId: appId! }).then((notebook) => {
          setNotebook(notebook);
          selectMainNote({ notebook });
        });
      });
  };
  return replyConversation;
};

export const useChatLoading: () => [
  boolean,
  (chatLoading: boolean) => void
] = () => {
  const chatLoading = useSelector((state: RootState) => state.main.chatLoading);
  const dispatch = useDispatch();
  const setChatLoading = (chatLoading: boolean) => {
    dispatch({ type: ActionType.SET_CHAT_LOADING, chatLoading });
  };
  return [chatLoading, setChatLoading];
};

export const useDeleteNoteTable: () => (config: {
  tableId: string;
}) => void = () => {
  const [notebook, setNotebook] = useNotebook();
  const [appId, setAppId] = useAppId();
  const selectMainNote = useSelectMainNote();

  const deleteNoteTable: (config: { tableId: string }) => void = ({
    tableId,
  }) => {
    deleteTable({ tableId })
      .then((response) => getNotebook({ appId: appId! }))
      .then((notebook) => {
        setNotebook(notebook);
        selectMainNote({ notebook });
      });
  };
  return deleteNoteTable;
};

export const useDeletePullConnector: () => (config: {
  connectorId: string;
}) => void = () => {
  const [appId, setAppId] = useAppId();
  const handleConnectorsResponse = useHandleConnectorsResponse();

  const deletePullConnector: (config: { connectorId: string }) => void = ({
    connectorId,
  }) => {
    deletePullConnectorApi({ appId: appId!, connectorId }).then(() => {
      getConnectors({ appId: appId! }).then((connectorsResponse) => {
        handleConnectorsResponse(connectorsResponse);
      });
    });
  };

  return deletePullConnector;
};

export const useAuthToken: () => [
  string | undefined,
  (authToken: string) => void
] = () => {
  const authToken = useSelector((state: RootState) => state.main.authToken);
  const dispatch = useDispatch();
  const setAuthToken = (authToken: string) => {
    dispatch({ type: ActionType.SET_AUTH_TOKEN, authToken });
  };
  return [authToken, setAuthToken];
};

export const useFetchAuthToken: () => void = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useAuthToken();
  useEffect(() => {
    if (!isAuthenticated) return;
    getAccessTokenSilently().then(setAuthToken);
  }, [isAuthenticated]);
};

export const useInitialPageLoaded: () => [
  boolean,
  (initialPageLoaded: boolean) => void
] = () => {
  const initialPageLoaded = useSelector(
    (state: RootState) => state.main.initialPageLoaded ?? false
  );
  const dispatch = useDispatch();
  const setInitialPageLoaded = (initialPageLoaded: boolean) => {
    dispatch({ type: ActionType.SET_INITIAL_PAGE_LOADING, initialPageLoaded });
  };
  return [initialPageLoaded, setInitialPageLoaded];
};

export const useSetInitialPage: () => void = () => {
  const { isAuthenticated } = useAuth0();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [initialPageLoaded, setInitialPageLoaded] = useInitialPageLoaded();

  useEffect(() => {
    setInitialPageLoaded(true);
    if (isAuthenticated) {
      setSelectedPage({ contentType: PageContentType.LOADING_ACCOUNT });
    } else {
      setSelectedPage({ contentType: PageContentType.NOT_LOGGED_IN });
    }
  }, [initialPageLoaded]);
};

export const useNavigateTable: () => (tableId: string) => void = () => {
  const connectorTables = useSelector(
    (state: RootState) => state.main.connectorTables
  );
  const setSelectedPullConnector = useSetSelectedPullConnector();

  return (tableId: string) => {
    const tableMatches = (conTable: any) => conTable["table-id"] === tableId;
    const findTableInConEntry = (conEntry: any[]) => {
      const [connectorId, conTables] = conEntry;
      const foundConTable = R.find(tableMatches, conTables);
      return foundConTable && [connectorId, foundConTable];
    };
    const findEntry = R.compose(
      R.head,
      R.filter((x: any) => x),
      R.map(findTableInConEntry)
    );
    const foundEntry: any = findEntry(Object.entries(connectorTables));
    if (foundEntry) {
      const [foundConId, foundConTable] = foundEntry;
      setSelectedPullConnector({
        connectorId: foundConId,
        tableId,
      });
    } else {
      document.getElementById(tableId)?.scrollIntoView();
    }
  };
};
