import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Table as MuiTable,
  Paper,
  Select,
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import remarkGfm from "remark-gfm";
import { fetchTableCount, fetchTablePage, getTableLogs } from "../api";
import {
  useAppId,
  useNavigateTable,
  useTableInputs,
  useTableSpec,
} from "../hook";
import { useSetTableTrigger } from "../hook/table";
import {
  Table,
  TableSpec,
  TriggerIndex,
  indexToTrigger,
  triggerToIndex,
} from "../types/table";

export const DataTable: React.FC<{ table: Table }> = ({ table }) => {
  const tableId = table?.["table-id"];
  const [index, setIndex] = useState<number>(0);
  const [rows, setRows] = useState<string[][]>([]);
  const header = table?.header ?? [];
  const [isLoading, setIsLoading] = useState(true);
  const page = index;
  const setPage = (page: number) => setIndex(page);
  const [tableCount, setTableCount] = useState<number>(0);
  const [appId, setAppId] = useAppId();
  const theme = useTheme();

  useEffect(() => {
    if (!appId || !tableId) return;

    fetchTableCount({ appId: appId, tableId }).then((res) => {
      setTableCount(res.count);
    });
  }, [tableCount, appId, tableId]);

  useEffect(() => {
    if (!tableId || !appId) return;
    setIsLoading(true);
    fetchTablePage({ appId, index, tableId, pageSize: 10 }).then((res) => {
      setRows(res.rows);
      setIsLoading(false);
    });
  }, [tableId, index]);

  useEffect(() => {
    if (!table?.rows) return;
    setIsLoading(false);
    setRows(table.rows);
  }, [table?.rows?.length]);

  return (
    <Box sx={{ flexDirection: "column" }}>
      <TableContainer component={Paper} sx={{ maxHeight: "40rem" }}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {header?.map((name: any, index: number) => (
                <TableCell sx={{ height: "4rem" }} key={index}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {rows?.map((row: any, rowIndex: number) => (
                <TableRow key={rowIndex}>
                  {row.map((cell: any, cellIndex: number) => (
                    <TableCell sx={{ height: "4rem" }} key={cellIndex}>
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      {isLoading && (
        <Box
          sx={{
            height: "40rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "2rem",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <TablePagination
        component="div"
        onPageChange={(e, value) => setPage(value)}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        page={page}
        count={tableCount}
        showFirstButton={true}
        showLastButton={true}
      />
    </Box>
  );
};

export const CodeTab: React.FC<{ tableSpec?: TableSpec }> = ({ tableSpec }) => {
  const code = tableSpec?.code?.trim().startsWith("```python")
    ? tableSpec.code
    : `\`\`\`python\n${tableSpec?.code}\n\`\`\``;

  return (
    <Box sx={{ overflowY: "auto" }}>
      <ReactMarkdown
        components={{
          code(props) {
            const { children, className, node, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <SyntaxHighlighter language={match[1]!} style={tomorrowNight}>
                {String(children)}
              </SyntaxHighlighter>
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
        }}
        remarkPlugins={[remarkGfm]}
      >
        {code}
      </ReactMarkdown>
    </Box>
  );
};

export const LogsTab: React.FC<{ tableSpec?: TableSpec }> = ({ tableSpec }) => {
  const [logs, setLogs] = useState<string | undefined>();
  const [logsLoading, setLogsLoading] = useState(true);
  const tableId = tableSpec?.["table-id"];
  const [appId, setAppId] = useAppId();

  useEffect(() => {
    if (!tableId) return;
    setLogsLoading(true);
    getTableLogs({ tableId, appId: appId! }).then(({ logs }) => {
      setLogsLoading(false);
      setLogs(logs);
    });
  }, [tableId]);

  return (
    <Box sx={{ overflowY: "auto" }}>
      {logsLoading ? (
        <Box
          sx={{ padding: "1rem", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <pre
          style={{
            backgroundColor: "rgb(29, 31, 33)",
            color: "rgb(197, 200, 198)",
          }}
        >
          {logs}
        </pre>
      )}
    </Box>
  );
};

export const UpdatesTab: React.FC<{ tableSpec?: TableSpec }> = ({
  tableSpec,
}) => {
  const tableId = tableSpec?.["table-id"];
  const updateInterval = tableSpec?.update_interval;
  const minutes = updateInterval?.minutes;
  const hours = updateInterval?.hours;
  const days = updateInterval?.days;
  const seconds = updateInterval?.seconds;
  const trigger = tableSpec?.trigger!;
  const selectValue = Number(
    trigger ? triggerToIndex[trigger] : TriggerIndex.ALWAYS
  );
  const setTableTrigger = useSetTableTrigger();

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Box
        sx={{
          padding: "1rem",
          gap: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{ padding: "1rem", flexGrow: 1, flexBasis: 1 }}
          elevation={5}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h6">Run Frequency</Typography>
            <Typography variant="body1">
              This is how often this code is executed.
            </Typography>
            <TextField disabled={true} label="Days" value={days ?? 0} />
            <TextField disabled={true} label="Hours" value={hours ?? 0} />
            <TextField disabled={true} label="Minutes" value={minutes ?? 0} />
            <TextField disabled={true} label="Seconds" value={seconds ?? 0} />
          </Box>
        </Paper>
        <Paper
          sx={{ padding: "1rem", flexGrow: 1, flexBasis: 1 }}
          elevation={5}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h6">Trigger</Typography>
            <Typography variant="body1">
              This is what constitutes for an input to this code.
            </Typography>
            <Typography variant="body1">
              Always: Every row in the input table will be included as an input
              to this code.
            </Typography>
            <Typography variant="body1">
              Edit: Only rows that change value from the last time this has run.
              This means another value other than the primary key.
            </Typography>
            <Typography variant="body1">
              Delete: Only rows that are no longer present since the last time
              this has run. This means any row in which the primary key is no
              longer present.
            </Typography>
            <Typography variant="body1">
              Create: Only new rows since the last time this has run. This means
              any row in which a distinct primary key has been added to the
              input since the last time this code was run.
            </Typography>
            <Select
              label="Trigger"
              value={selectValue}
              onChange={(e) => {
                const numVal = Number(e.target.value) as TriggerIndex;
                const newTrigger = indexToTrigger[numVal];
                setTableTrigger({ tableId: tableId!, trigger: newTrigger });
              }}
            >
              <MenuItem value={TriggerIndex.ALWAYS}>Always</MenuItem>
              <MenuItem value={TriggerIndex.CREATE}>Create</MenuItem>
              <MenuItem value={TriggerIndex.UPDATE}>Update</MenuItem>
              <MenuItem value={TriggerIndex.DELETE}>Delete</MenuItem>
            </Select>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export const InputsTab: React.FC<{ tableSpec?: TableSpec }> = ({
  tableSpec,
}) => {
  const tableId = tableSpec?.["table-id"];
  const tableInputs = useTableInputs(tableId);
  const [appId, setAppId] = useAppId();
  const navigateTable = useNavigateTable();

  return (
    <Box sx={{ overflowY: "auto", flexDirection: "column" }}>
      {tableInputs.map((table, index) => {
        return (
          <Button onClick={() => navigateTable(table["table-id"])} key={index}>
            {table.name}
          </Button>
        );
      })}
    </Box>
  );
};

export const CodeTable: React.FC<{
  table: Table;
}> = ({ table }) => {
  const tableId = table?.["table-id"];
  const tableSpec = useTableSpec(tableId);
  const [selectedTab, setSelectedTab] = useState(0);
  const sideEffectTable = tableSpec?.["code-type"] === "side-effect";

  return (
    <>
      <Tabs value={selectedTab}>
        <Tab color="primary" label="Table" onClick={() => setSelectedTab(0)} />
        <Tab color="primary" label="Code" onClick={() => setSelectedTab(1)} />
        <Tab color="primary" label="Logs" onClick={() => setSelectedTab(2)} />
        <Tab color="primary" label="Inputs" onClick={() => setSelectedTab(3)} />
        {sideEffectTable && (
          <Tab
            color="primary"
            label="Update Trigger"
            onClick={() => setSelectedTab(4)}
          />
        )}
      </Tabs>
      {selectedTab === 0 ? (
        <DataTable table={table} />
      ) : selectedTab === 1 ? (
        <CodeTab tableSpec={tableSpec} />
      ) : selectedTab === 2 ? (
        <LogsTab tableSpec={tableSpec} />
      ) : selectedTab === 3 ? (
        <InputsTab tableSpec={tableSpec} />
      ) : (
        <UpdatesTab tableSpec={tableSpec} />
      )}
    </>
  );
};

export const ErrorTab: React.FC<{
  tableSpec: TableSpec;
}> = ({ tableSpec }) => {
  const { error, error_line, error_line_number, message, stack_trace } =
    tableSpec["execute-error"]!;

  return (
    <Box padding={2}>
      <Typography variant="h5" color="error" gutterBottom>
        Error: {error || "Unknown Error"}
      </Typography>
      {message && (
        <Typography variant="body1" gutterBottom>
          <strong>Message:</strong> {message}
        </Typography>
      )}
      {error_line && (
        <Typography variant="body1" gutterBottom>
          <strong>Error Line:</strong> {error_line} (Line {error_line_number})
        </Typography>
      )}
      {stack_trace && (
        <Paper
          elevation={7}
          style={{ padding: "1rem", marginTop: "1rem", overflowX: "auto" }}
        >
          <Typography variant="subtitle1" gutterBottom>
            <strong>Stack Trace:</strong>
          </Typography>
          <Typography variant="body2" component="pre">
            {stack_trace}
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export const ErrorTable: React.FC<{
  tableSpec: TableSpec;
}> = ({ tableSpec }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <Tabs value={selectedTab}>
        <Tab color="primary" label="Error" onClick={() => setSelectedTab(0)} />
        <Tab color="primary" label="Code" onClick={() => setSelectedTab(1)} />
      </Tabs>
      {selectedTab === 0 ? (
        <ErrorTab tableSpec={tableSpec} />
      ) : (
        <CodeTab tableSpec={tableSpec} />
      )}
    </>
  );
};

export enum TableType {
  "CODE_TABLE" = "CODE_TABLE",
  "DATA_TABLE" = "DATA_TABLE",
  "ERROR_CODE_TABLE" = "ERROR_CODE_TABLE",
}

export const SearchTable: React.FC<{
  tableSpec?: TableSpec;
  table: Table;
  tableType?: TableType;
}> = ({ table, tableType = TableType.DATA_TABLE, tableSpec }) => {
  return (
    <Stack spacing={2}>
      {tableType === TableType.CODE_TABLE ? (
        <CodeTable table={table} />
      ) : tableType === TableType.DATA_TABLE ? (
        <DataTable table={table} />
      ) : (
        <ErrorTable tableSpec={tableSpec!} />
      )}
    </Stack>
  );
};
