export interface TableRunResponse {
  msg: string;
  "no-effects"?: boolean;
  "write-table-rows"?: {
    stats: {
      insert?: number;
      update?: number;
      delete?: number;
    };
  };
}

export interface TableRunResponses {
  [tableId: string]: TableRunResponse;
}

export enum TriggerIndex {
  ALWAYS = 0,
  CREATE = 1,
  UPDATE = 2,
  DELETE = 3,
}

export enum TableTrigger {
  ALWAYS = "always",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

export const triggerToIndex = {
  [TableTrigger.ALWAYS]: TriggerIndex.ALWAYS,
  [TableTrigger.CREATE]: TriggerIndex.CREATE,
  [TableTrigger.UPDATE]: TriggerIndex.UPDATE,
  [TableTrigger.DELETE]: TriggerIndex.DELETE,
};

export const indexToTrigger = {
  [TriggerIndex.ALWAYS]: TableTrigger.ALWAYS,
  [TriggerIndex.CREATE]: TableTrigger.CREATE,
  [TriggerIndex.UPDATE]: TableTrigger.UPDATE,
  [TriggerIndex.DELETE]: TableTrigger.DELETE,
};

export interface TableSpec {
  code: string;
  name: string;
  "code-type": "data-transform" | "side-effect";
  description: "descrption";
  inputs: string[];
  "table-id": string;
  "execute-error"?: {
    error: "true";
    error_line: string;
    message: string;
    stack_trace: string;
    error_line_number: string;
  };
  trigger?: TableTrigger;
  update_interval: {
    minutes?: number;
    seconds?: number;
    hours?: number;
    days?: number;
  };
}

export interface TableMap {
  [id: string]: Table;
}

export interface TableSpecMap {
  [id: string]: TableSpec;
}

export interface Table {
  name: string;
  "file-id": string;
  "primary-key": string[];
  location: string;
  header: string[];
  "table-id": string;
  rows?: string[][];
}

export interface TableLogs {
  logs: string;
  "app-id": string;
  "table-id": string;
}

export interface TablePagesMap {
  [id: string]: { [index: number]: string[][] };
}
