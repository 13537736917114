import { useDispatch, useSelector } from "react-redux";
import { getNotebook, runTable, setTableTrigger } from "../api";
import { ActionType, AppDispatch, RootState } from "../reducer";
import {
  TableRunResponse,
  TableRunResponses,
  TableTrigger,
} from "../types/table";
import { useAppId, useNotebook } from "../hook";

export const useTableRunResponses: () => [
  TableRunResponses,
  (tableRunResponses: TableRunResponses) => void
] = () => {
  const tableRunResponses = useSelector(
    (state: RootState) => state.main.tableRunResponses
  );
  const dispatch = useDispatch<AppDispatch>();
  const setTableRunResponses = (tableRunResponses: TableRunResponses) =>
    dispatch({ type: ActionType.SET_TABLE_RUN_RESPONSES, tableRunResponses });
  return [tableRunResponses, setTableRunResponses];
};

export const useTableRunResponse: (
  tableId: string
) => TableRunResponse | undefined = (tableId) => {
  const [tableRunResponses, setTableRunResponses] = useTableRunResponses();
  return tableRunResponses[tableId];
};

export const useRunTable: () => (config: { tableId: string }) => void = () => {
  const [tableRunResponses, setTableRunResponses] = useTableRunResponses();

  return ({ tableId }) => {
    runTable({ tableId }).then((res) => {
      const newTableRunResponses = { ...tableRunResponses, [tableId]: res };
      console.log("RUN_RES", res);
      setTableRunResponses(newTableRunResponses);
    });
  };
};

export const useSetTableTrigger: () => (config: {
  tableId: string;
  trigger: TableTrigger;
}) => void = () => {
  const [appId] = useAppId();
  const [notebook, setNotebook] = useNotebook();

  return ({ tableId, trigger }) => {
    setTableTrigger({ tableId, trigger }).then((res) => {
      getNotebook({ appId: appId! }).then((notebook) => {
        setNotebook(notebook);
      });
    });
  };
};
