import { useAuth0 } from "@auth0/auth0-react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPaymentMethodsResponse,
  GetSubscriptionResponse,
  addWorkspace,
  addWorkspaceUser,
  createSubscription,
  deleteWorkspace,
  deleteWorkspaceUser,
  getAllUsers,
  getAllWorkspaces,
  getPaymentMethods,
  getSubscription,
  getWorkspaceUsers,
  setSelectedApp,
} from "../api";
import { useAppId, useAppOwner, useApps, useSelectedPage } from "../hook";
import {
  ActionType,
  App,
  AppDispatch,
  AppUser,
  PageContentType,
  PaymentMethod,
  RootState,
  StripeCustomer,
} from "../reducer";
import { useEffect } from "react";

export const useNavigateSettingsPage: () => () => void = () => {
  const [selectedPage, setSelectedPage] = useSelectedPage();

  return () => {
    setSelectedPage({ contentType: PageContentType.SETTINGS });
  };
};

export const useAllUsers: () => [
  AppUser[],
  (messages: AppUser[]) => void
] = () => {
  const allUsers = useSelector((state: RootState) => state.main.allUsers);
  const dispatch = useDispatch<AppDispatch>();
  const setAllUsers = (allUsers: AppUser[]) =>
    dispatch({ type: ActionType.SET_ALL_USERS, allUsers });
  return [allUsers, setAllUsers];
};

export const useAppsFromAllSources: () => App[] = () => {
  const [apps, setApps] = useApps();
  const [allWorkspaces, setAllWorkspaces] = useAllWorkspaces();
  return R.uniqBy(R.prop("id"), [...apps, ...allWorkspaces]);
};

export const useAppIdToApp: () => (appId: string) => App = () => {
  const apps = useAppsFromAllSources();
  const appItToApp = Object.fromEntries(apps.map((app) => [app.id, app]));

  return (appId) => {
    return appItToApp[appId];
  };
};

export const useAllWorkspaces: () => [
  App[],
  (allWorkspaces: App[]) => void
] = () => {
  const allWorkspaces = useSelector(
    (state: RootState) => state.main.allWorkspaces
  );
  const dispatch = useDispatch<AppDispatch>();
  const setAllWorkspaces = (allWorkspaces: App[]) =>
    dispatch({ type: ActionType.SET_ALL_WORKSPACES, allWorkspaces });
  return [allWorkspaces, setAllWorkspaces];
};

export const useFetchAllWorkspaces: () => () => void = () => {
  const [allWorkspaces, setAllWorkspaces] = useAllWorkspaces();

  return () => {
    getAllWorkspaces().then((response) => {
      setAllWorkspaces(response.apps);
    });
  };
};

export const useDeleteWorkspace: () => (config: {
  appId: string;
}) => void = () => {
  const fetchAllWorkspaces = useFetchAllWorkspaces();
  const fetchAllUsers = useFetchAllUsers();

  return ({ appId }) => {
    deleteWorkspace({ appId }).then((response) => {
      fetchAllWorkspaces();
      fetchAllUsers();
    });
  };
};

export const useAddWorkspace: () => (config: {
  ownerName: string;
  ownerEmail: string;
  appName: string;
}) => void = () => {
  const fetchAllWorkspaces = useFetchAllWorkspaces();
  const fetchAllUsers = useFetchAllUsers();

  return ({ ownerEmail, ownerName, appName }) => {
    addWorkspace({ ownerEmail, ownerName, appName }).then((response) => {
      fetchAllWorkspaces();
      fetchAllUsers();
    });
  };
};

export const useFetchAllUsers: () => () => void = () => {
  const [allUsers, setAllUsers] = useAllUsers();

  return () => {
    getAllUsers().then((response) => {
      setAllUsers(response.users);
    });
  };
};

export const useWorkspaceUsers: () => [
  AppUser[],
  (messages: AppUser[]) => void
] = () => {
  const workspaceUsers = useSelector(
    (state: RootState) => state.main.workspaceUsers
  );
  const dispatch = useDispatch<AppDispatch>();
  const setWorkspaceUsers = (workspaceUsers: AppUser[]) =>
    dispatch({ type: ActionType.SET_WORKSPACE_USERS, workspaceUsers });
  return [workspaceUsers, setWorkspaceUsers];
};

export const useFetchWorkspaceUsers: () => (config: {
  appId: string;
}) => void = () => {
  const [workspaceUsers, setWorkspaceUsers] = useWorkspaceUsers();

  return ({ appId }) => {
    getWorkspaceUsers({ appId }).then((response) => {
      setWorkspaceUsers(response.users);
    });
  };
};

export const useDeleteWorkspaceUser: () => (config: {
  appId: string;
  email: string;
}) => void = () => {
  const fetchWorkspaceUsers = useFetchWorkspaceUsers();
  const fetchAllUsers = useFetchAllUsers();

  return ({ appId, email }) => {
    deleteWorkspaceUser({ appId, email }).then((response) => {
      fetchAllUsers();
      fetchWorkspaceUsers({ appId });
    });
  };
};

export const useAddWorkspaceUser: () => (config: {
  appId: string;
  email: string;
  name: string;
}) => void = () => {
  const fetchWorkspaceUsers = useFetchWorkspaceUsers();
  const fetchAllUsers = useFetchAllUsers();
  const [appId, setAppId] = useAppId();

  return ({ appId: addAppId, email, name }) => {
    addWorkspaceUser({ appId: addAppId, email, name }).then((response) => {
      fetchAllUsers();
      fetchWorkspaceUsers({ appId: appId! });
    });
  };
};

export const useSetSelectedApp: () => (config: {
  appId: string;
  email: string;
}) => void = () => {
  const [appId, setAppId] = useAppId();

  return ({ appId, email }) => {
    setSelectedApp({ appId: appId, email }).then((response) => {
      setAppId(appId);
    });
  };
};

export const usePaymentMethods: () => [
  GetPaymentMethodsResponse | undefined,
  (paymentMethods: GetPaymentMethodsResponse | undefined) => void
] = () => {
  const paymentMethods = useSelector(
    (state: RootState) => state.main.paymentMethods
  );
  const dispatch = useDispatch<AppDispatch>();
  const setWorkspaceUsers = (
    paymentMethods: GetPaymentMethodsResponse | undefined
  ) => dispatch({ type: ActionType.SET_PAYMENT_METHODS, paymentMethods });
  return [paymentMethods, setWorkspaceUsers];
};

export const useStripeCustomer: () => [
  StripeCustomer | undefined,
  (stripeCustomer: StripeCustomer) => void
] = () => {
  const stripeCustomer = useSelector(
    (state: RootState) => state.main.stripeCustomer
  );
  const dispatch = useDispatch<AppDispatch>();
  const setStripeCustomer = (stripeCustomer: StripeCustomer) =>
    dispatch({ type: ActionType.SET_STRIPE_CUSTOMER, stripeCustomer });
  return [stripeCustomer, setStripeCustomer];
};

export const useDefaultPaymentMethod: () => PaymentMethod | undefined = () => {
  const [paymentMethods] = usePaymentMethods();
  const defaultPaymentMethod = R.find(
    (method) =>
      method["stripe-id"] ===
      (paymentMethods?.["default-payment-method"] as any),
    paymentMethods?.methods || []
  );
  return defaultPaymentMethod;
};

export const useFetchPaymentMethods: () => () => Promise<void> = () => {
  const [paymentMethods, setPaymentMethods] = usePaymentMethods();
  const [stripeCustomer, setStripeCustomer] = useStripeCustomer();
  const [appOwner] = useAppOwner();
  const email = appOwner!;

  return () => {
    setPaymentMethods(undefined);
    return getPaymentMethods({ email }).then((response) => {
      const { "stripe-customer-id": stripeCustomerId, email } = response;
      setStripeCustomer({ "stripe-customer-id": stripeCustomerId, email });
      setPaymentMethods(response);
    });
  };
};

export const useStripeSubscription: () => [
  GetSubscriptionResponse | undefined,
  (stripeSubscription: GetSubscriptionResponse | undefined) => void
] = () => {
  const stripeSubscription = useSelector(
    (state: RootState) => state.main.stripeSubscription
  );
  const dispatch = useDispatch<AppDispatch>();
  const setStripeSubscription = (
    stripeSubscription: GetSubscriptionResponse | undefined
  ) =>
    dispatch({ type: ActionType.SET_STRIPE_SUBSCRIPTION, stripeSubscription });
  return [stripeSubscription, setStripeSubscription];
};

export const useFetchStripeSubscription: () => () => Promise<void> = () => {
  const [stripeSubscription, setStripeSubscription] = useStripeSubscription();
  const [appOwner] = useAppOwner();
  const email = appOwner!;

  return () => {
    setStripeSubscription(undefined);
    return getSubscription({ email }).then((response) => {
      setStripeSubscription(response);
    });
  };
};

export const useInitCustomer: () => void = () => {
  const [paymentMethods, setPaymentMethods] = usePaymentMethods();
  const [stripeSubscription, setStripeCustomer] = useStripeSubscription();
  const fetchSubscription = useFetchStripeSubscription();
  const fetchPaymentMethods = useFetchPaymentMethods();
  const [appOwner] = useAppOwner();
  const email = appOwner!;
  const notFound = stripeSubscription?.["not-found"];

  useEffect(() => {
    if (!email) return;

    fetchPaymentMethods().then(() => {
      fetchSubscription();
    });
  }, [email]);

  useEffect(() => {
    if (!notFound) return;
    createSubscription({ email }).then(() => {
      fetchSubscription();
    });
  }, [notFound]);
};

export const useIsAppOwner = () => {
  const [appOwner] = useAppOwner();
  const { user } = useAuth0();
  const email = user?.email;
  return email && email === appOwner ? true : false;
};

export const useSubscriptionStatus = () => {
  const [paymentMethods, setPaymentMethods] = usePaymentMethods();
  const [stripeSubscription, setStripeSubscription] = useStripeSubscription();
  const isAppOwner = useIsAppOwner();

  const periodStart = stripeSubscription?.["current-period-start"]!;
  const periodEnd = stripeSubscription?.["current-period-end"]!;
  const cancelAtPeriodEnd = stripeSubscription?.["cancel-at-period-end"];
  const canceled = stripeSubscription?.status === "canceled";
  const trialing = stripeSubscription?.status === "trialing";
  const active = stripeSubscription?.status === "active";
  const hasAccess = active || trialing;
  const hasPaymentMethod = paymentMethods?.methods.length !== 0;
  const canCancel =
    (trialing || active) && !cancelAtPeriodEnd && hasPaymentMethod;
  const canActivate =
    (canceled || cancelAtPeriodEnd) && hasPaymentMethod && isAppOwner;
  const addPaymentMethodPrompt = hasAccess && !hasPaymentMethod;
  const canEnableRenewal = canActivate && !canceled && isAppOwner;
  const canPayImmediately = canActivate && canceled && isAppOwner;
  const renewing = active && !cancelAtPeriodEnd;
  const loading = !stripeSubscription;
  const workspaceOwnerPrompt = !isAppOwner;

  return {
    periodStart,
    periodEnd,
    renewing,
    canPayImmediately,
    canEnableRenewal,
    addPaymentMethodPrompt,
    canCancel,
    trialing,
    hasAccess,
    cancelAtPeriodEnd,
    active,
    canceled,
    loading,
    workspaceOwnerPrompt,
  };
};

export const useUserIsAppOwner: () => (config: {
  email: string;
  appId?: string;
}) => boolean = () => {
  const apps = useAppsFromAllSources();
  const appOwnerMap = Object.fromEntries(
    apps.map((app) => [app.id, app["owner-email"]])
  );
  const [appIdState] = useAppId();
  return ({ email, appId: appIdParam }) => {
    const appId = appIdParam ?? appIdState;
    return appId ? appOwnerMap[appId] === email : false;
  };
};
